import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PlatformNavList from '../components/platform/nav-items';
import Available_paltforms from "../components/Platform_page/available_paltforms"
import PlatformHeaderTiles from "../components/platform/platform_header";
import AboutBizlab from "../components/platform/Bizlab/aboutBizlab";
import Modules_section from "../components/platform/Bizlab/biz_lab_modules";
import Get_started from "../components/platform/Bizlab/biz_lab_getStarted";
import Faq_Accordian from "../components/platform/Bizlab/faq_accordian";
import ContactUS from "../components/contact-us";


const BizLabPage = () => (
  <Layout navName="Platform">
    <Seo title="BizLab" />
    <PlatformNavList navName="Bizlab"/>
    <PlatformHeaderTiles header="Business Laboratory" imageName="businessLaboratory"/>
    <AboutBizlab />
   <Modules_section/>
   <Get_started/>
   <Faq_Accordian/>
   <ContactUS />
  </Layout>
)

export default BizLabPage
