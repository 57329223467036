import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Heading } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import king_icon from '../../../images/king_icon.png'

export default function AboutBizlab(props) {
    return (
        <Section style={{ color: "#2D0E4A" }} >
            {/* <Container width="tight" style={{ color: "#2D0E4A" }} > */}

                <Flex >
                    <Box>
                        <Heading>
                            <StaticImage src='../../images/Skill_elevator_icon.png' alt="Skill_elevator_icon" width={30} />About Bizlab
                        </Heading>
                        <Text>
                            Business Laboratory Provides virtual Experience of various Accounting and Tax works.
                        </Text>

                    </Box>
                </Flex>
                <Flex gap={4} variant="responsive">
                    <Box>
                        <Text>Business laboratory is a consolidation of 20 modules that represents
                            various transactional work in every company or business. The Objective of this paltform
                            is to give an opportunity to student to undergo needed learnning on realtime works and feel
                            like working in a company. It is Laboratory for Business in a way that enable sudent to
                            experiement themselves with virual transactions in the business. Student need to register
                            each module and complete the module, one after one. After register, each subscriber will
                            be given an opportunity to work with one virtual company. Each module give a feel of real
                            time office/ work place job and subscriber will be given limited level of transactions in
                            the screen. As an example, 'Practical Accounting and Book Keeping' module gives an insight
                            in to the accounting entries using various vouchers and bill. It gives the complete work
                            experience in accounting.
                        </Text>
                        <Flex gap={4} variant="responsive">
                        <Box>
                        <StaticImage src='../../../images/work_virtual_company.png' alt="work_virtual_company" />
                            <Text>20 in-depth working modules</Text>
                        </Box>
                        <Box>
                          <StaticImage src='../../../images/king_icon.png' alt="king_icon" />
                        <Text>Access to 1 virtual company to work</Text>
                        </Box>
                        <Box>
                          <StaticImage src='../../../images/verified.png' alt="verified" />
                        <Text>Experience certificate with Pro-Accountant Qualification</Text>
                        </Box>
                        </Flex>
                    </Box>
                    <Box>
                          <StaticImage src='../../../images/biz_lab_images/bizlab_1.png' alt="bizlab_1" />
                    </Box>
                </Flex>
                <Container>
                <Flex>
                <Box gap={4} variant="responsive">
                          <StaticImage src='../../../images/biz_lab_images/bizlab_2.png' alt="bizlab_2" />
                    </Box>
                </Flex>
                </Container>
               
            {/* </Container> */}


        </Section>
    )
}
