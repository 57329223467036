import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Heading } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import king_icon from '../../../images/king_icon.png'
import { AiFillCaretDown } from "react-icons/ai";

export default function Faq_Accordian(props) {
    // const [toggle,setToggle]=React.useState(null)
    // React.useEffect(()=>{
    //     if(!toggle){
    //         var b={}
    //         toggle.map(data=>{
    //             b[data. content]=false
    //         })
    //         setToggle(b)

    //     }
    // }, [toggle])
    // const onToggle=(item)=>{
    //     setToggle({...toggle,[item.content]:!toggle[item.content]})
    // }
    return (
        <Section style={{ color: "#fff",background: "radial-gradient(90.65% 254.2% at 13.07% -83.82%, #2B237C 0%, #251C72 31%, #0F054C 100%)",transform:"skewY(-5deg)" }} >
        <Container width="tight" style={{ color: "#fff",transform:"skewY(5deg)" }} >

                <Flex gap={4} variant="responsive">
                    <Box>
                        <Heading>
                        FAQ ?
                        </Heading>
                     
                    </Box>
                </Flex>
                <Flex style={{background: "#FFFFFF",borderRadius: "5px",color:"#374A59"}}>
                    <Box >
                        <Text>Why should I use Bizlab? <AiFillCaretDown />     </Text>
                    </Box>    
                </Flex>
                <Flex style={{background: "#FFFFFF",borderRadius: "5px",color:"#374A59"}}>
                    <Box >
                        <Text>Why should I use Bizlab? <AiFillCaretDown />     </Text>
                    </Box>    
                </Flex>
                <Flex style={{background: "#FFFFFF",borderRadius: "5px",color:"#374A59"}}>
                    <Box >
                        <Text>Why should I use Bizlab? <AiFillCaretDown />     </Text>
                    </Box>    
                </Flex>



            </Container>


        </Section>
    )
}
